/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
  display: flex;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  height: 100vh;
}

.calendar .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  border-bottom: 1px solid var(--border-color);
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .order {
  height: 9vh;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
  cursor: pointer;
  position: relative;
  /* CSS for handling overflow text */
  overflow: hidden;
  /* Hide any text that overflows the container */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) to represent clipped text */
  white-space: normal;
  /* Allow text to wrap to the next line */
}


.hazard-icon {
  top: 0.5vh;
  right: 0.5vh;
  width: 2vh;
  height: 2vh;
  position: absolute;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .hazard-icon {
    top: 0.2vh;
    right: 0.2vh;
    width: 1vh;
    height: 1vh;
    position: absolute;
    cursor: pointer;
  }
}


.info-icon {
  width: 15px;
  height: 15px;
  right: 0.5vh;
  bottom: 2px;
  cursor: pointer;
  position: absolute;
}


.calendar .body .order:hover {
  border: 2px solid black;
}

.calendar .body .order-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
  text-decoration: none;
  color: black;
  font-size: 3vw;
  /* Text color */
}

.calendar .body .number {
  font-size: 1.9vh;
  font-weight: bold;
  /* Make the order number bold */
  margin-top: 0.5vh;
}

.calendar .body .designer-number {
  font-size: 1.9vh;
  font-weight: bold;
  /* Make the order number bold */
  margin-top: 0.5vh;
  border: 2px solid black;
  border-radius: 50%;
  width: 30%;
  margin: 0 auto; /* Center horizontally within the parent */
}

.calendar .body .customer-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.7vh;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 5);
  width: calc(100% / 5);
}

@media (max-width: 1500px) {
  .calendar .body .number {
    font-size: 1.5vh;
    font-weight: bold;
    /* Make the order number bold */
    margin-top: 0.5vh;
  }
  .calendar .body .customer-name {
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4vh;
  }
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 4);
    width: calc(100% / 4);
  }
  .calendar .body .designer-number {
    font-size: 1.5vh;
    font-weight: bold;
    /* Make the order number bold */
    margin-top: 0.5vh;
    border: 2px solid black;
    border-radius: 50%;
    width: 40%;
    margin: 0 auto; /* Center horizontally within the parent */
  }
}
.customer-details {
  display: block;
  align-items: center;
  justify-content: center;
  /* Aligns text both vertically and horizontally in the center */
  width: 90%;
  margin: 0 auto;
  /* Ensure it fills the available height */
}

.customer-details .model-woodType {
  font-size: 1vh; /* Adjust the size as needed */
  color: #4d4c4c; /* Optional: Change color to distinguish it from the name */
  text-align: center; /* Center the text below the customer name */
  gap: 10px;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.work-state {
  width: 1vw;
  height: 1vw;
  display: inline-block;
  margin-right: 0.5vw;
  margin-left: 2vw;
}
.calendar .body .current-week {
  background-color: green;
  color: white;
}

.calendar .body .materials-running-out {
  border: 2px solid yellow;
}

.calendar .body .materials-out-of-storage {
  border: 2px solid red;
}

.calendar .body .week-header-container {
  position: sticky;
  top: 0;
  background-color: white;
  /* Adjust the background color as needed */
  z-index: 1;
  /* Ensure the header is displayed above other elements */
  top: 75px;
}

.week-header {
  padding: 3px;
  /* Add some padding for better visibility */
  border: 3px solid white;
  /* Add a border for better visibility */
}

.week-number {
  font-size: 15px;
}


.tooltip-container {
  position: relative;
}

.tooltip-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  z-index: 10 !important;
  width: 100%;
  font-size: 1vw;
}

.calendar .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 115%;
  padding: 1em 0;
  position: sticky;
  top: 0;
  /* Stick the footer to the top of the screen */
  background-color: #fff;
  /* Optional: Add a background color to the footer */
  z-index: 1;
  /* Ensure the footer stays above other content */
}

.button-grid-container {
  display: flex;
  flex-wrap: wrap;
  /* Allows items to wrap onto the next line */
  gap: 10px;
  /* Space between buttons */
  margin: 10px;
  /* Margin for top and bottom */
}

.gradoGreenButton {
  background-color: #013220;
  /* Grado green color */
  color: #ffffff;
  /* White text color */
  border: 2px solid transparent;
  /* Add transparent border */
  border-radius: 5px;
  /* Add some border radius for smooth edges */
  padding: 10px 10px;
  /* Add padding to the button */
  font-size: 15px;
  /* Font size */
  font-family: Arial, sans-serif;
  /* Set font family to Arial */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  /* Smooth transition for background color, text color */
  box-sizing: border-box;
  /* Ensure padding and border are included in width and height */
  text-align: center;
  /* Center text inside the button */
  min-height: 40px;
  /* Set a minimum height for the button */
  flex: 1 1 auto;
  /* Allow buttons to grow and shrink */
  max-width: 150px;
}

.gradoGreenButton:active {
  background-color: #ffffff;
  /* White background color on hover */
  color: #000000;
  /* Black text color on hover */
  border-color: #000000;
  /* Black border color on hover */
}

.gradoGreenButton:disabled {
  background-color: #4f6b61;
  /* Maintain Grado green background */
  color: rgba(255, 255, 255, 0.5);
  /* Lighter white text color for a disabled look */
  cursor: not-allowed;
  /* Change cursor to not-allowed when disabled */
  opacity: 0.6;
  /* Slightly reduce opacity to visually indicate the disabled state */
  border-color: rgba(255, 255, 255, 0.2);
  /* Light border color to match the disabled effect */
}

.storage-item {
  width: 170px;
  height: 230px;
  border-radius: 10px;
  padding: 2px;
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center content vertically */
  align-items: center;
  /* Center content horizontally */
  text-align: center;
  border: 4px solid transparent;
  /* Initially set border to transparent */
  color: #000000;
  background-color: white;
}

@media (max-width: 1080px) {
  .storage-item {
    max-width: 30vw;
    max-height: 30vh;
  }
}

.storage-item.green {
  border-color: green;
  /* Set border color to green */
}

.storage-item.yellow {
  border-color: goldenrod;
  /* Set border color to yellow */
}

.storage-item.red {
  border-color: red;
  /* Set border color to red */
}

.storage-grid-container {
  position: relative;
  /* To position child elements absolutely within this container */
}

.storage-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Occupy entire available width */
  min-height: 100%;
  /* Ensure a minimum height */
  flex-wrap: wrap;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin: 0 0 0 10px;
}

.bigheader {
  font-size: 175%;
  margin: 0 0 0 20px;
  color: #000000;
  align-items: center;
  justify-content: center;
}

.woodType-buttons {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
}

.woodType-button {
  width: 200px;
  height: 70px;
  border: none;
  background-color: rgb(231, 227, 227);
  padding: 5px 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  max-width: 25vw;
  border-radius: 10px;
  margin: 3px;
}

@media (max-width: 1080px) {
  .woodType-button {
    width: 200px;
    height: 70px;
    border: none;
    background-color: rgb(231, 227, 227);
    padding: 5px 10px;
    cursor: pointer;
    font-size: 2.8vw;
    font-weight: bold;
    max-width: 25vw;
    border-radius: 10px;
    margin: 3px;
  }
}

.woodType-button.active {
  background-color: green !important;
}

.woodType-button:hover {
  background-color: #e4e4e4;
}

.storage-page-container {
  display: flex;
}

/* Container for the modal */
.add-item-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Window inside the modal */
.add-item-window {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 440px;
  max-width: 90vw;
  /* Ensure the window is responsive on smaller screens */
  max-height: 80vh;
}

.navbar {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
}

.navbar-logo {
  width: 13vw;
  margin-right: 1rem;
  max-width: 140px;
}

.navbar-list {
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  display: flex;
}

.navbar-item {
  margin-right: 1rem;
}

.navbar-link {
  color: #000000;
  /* Default color */
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.5vw;
  margin-left: 10px;
}

@media (max-width: 1080px) {
  .navbar-link {
    color: #000000;
    /* Default color */
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 1.5vh;
    margin-left: 10px;
  }
}

.navbar-link.active {
  color: #ffc107;
  /* Change color for active link */
}

.navbar-button {
  color: #000000;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.3vw;
  font-family: 'Monaco', monospace;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-content: right;
}

.navbar-username {
  font-size: 1vw;
  display: flex;
}

.navbar-button:hover {
  color: #ffc107;
}

.navbar-dropdown {
  position: relative;
}

.navbar-menu-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  /* Adjust size as needed */
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}


.dot-matrix {
  font-family: 'Monaco', monospace;
  font-weight: bold;
  letter-spacing: 0.1em;

}

.search-bar-container {
  display: flex;
  align-items: center;
  width: 300px;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 5px;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
}

.search-button {
  background-color: #007bff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  margin-left: 5px;
}

.search-button i {
  color: #fff;
}

.date-filters {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
}

.react-datepicker-wrapper {
  width: 300px;
}

.react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 1080px) {
  .date-filters {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    flex-direction: column;
    /* Stack items vertically */
    padding: 10px;
  }

  .react-datepicker-wrapper {
    width: 90%;
    /* Make the date picker take up 90% of screen width */
    max-width: 300px;
    /* Set a maximum width for larger screens */
  }
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.orders-page {
  padding: 20px;
}


.orders-tabs {
  display: flex;
  gap: 20px;
  /* Space between buttons */
  align-items: center;
  flex-wrap: wrap;
  /* Allow tabs to wrap to the next row if needed */
}

.orders-tabs .selected-tab {
  padding: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #007bff;
  height: 50px;
  width: 150px;
  font-size: 15px;
  /* Font size */
  font-family: Arial, sans-serif;
  /* Set font family to Arial */
}

.orders-tabs .tab:hover {
  background-color: #bebcbc;
  transition: 0.3s ease;
}

.orders-tabs .selected-tab:disabled,
.orders-tabs .tab:disabled {
  opacity: 0.7;
}

.orders-tabs .tab {
  padding: 5px;
  border: none;
  background-color: #ebe8e8;
  cursor: pointer;
  border-radius: 5px;
  height: 50px;
  width: 150px;
  font-size: 15px;
  /* Font size */
  font-family: Arial, sans-serif;
  /* Set font family to Arial */
}

.orders-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.order-card {
  width: 200px;
  margin: 10px;
  padding: 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
  max-width: 25vw;
  max-height: 100px;

  /* CSS for handling overflow text */
  overflow: hidden;
  /* Hide any text that overflows the container */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) to represent clipped text */
  white-space: normal;
  /* Allow text to wrap to the next line */
}

.order-card:hover {
  background-color: #ADD8E6;
}

.order-card__number {
  font-weight: bold;
  font-size: 18px;
}

.order-card__customer {
  margin-top: 5px;
}

.no-orders-message {
  font-size: 16px;
  margin-top: 20px;
}

.modifyIcons {
  width: 30px;
  background-color: transparent;
  cursor: pointer;
}

.modifyIcons:hover {
  border: 1px solid black;
  border-radius: 5px;
  transition: border 0.3s ease;
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
}

.modify-amount-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modify-amount-window {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modify-amount-window h2 {
  margin-bottom: 10px;
}

.modify-amount-window button {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 0.7vw;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.modify-amount-window button:active {
  background-color: #e0e0e0;
}

.amount-controls .plus {
  background-color: #4CAF50;
  color: white;
  width: 80px;
  height: 80px;
  font-size: 30px;
}

.amount-controls .minus {
  background-color: #f44336;
  color: white;
  width: 80px;
  height: 80px;
  font-size: 30px;
}

.amount-controls .small-minus{
  background-color: #f44336;
  color: white;
  width: 50px;
  height: 50px;
  font-size: 18px;
}

.amount-controls .small-plus {
  background-color: #4CAF50;
  color: white;
  width: 50px;
  height: 50px;
  font-size: 18px;
}

.modify-amount-window input[type="text"] {
  margin: 0 10px;
  width: 50px;
  height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modify-amount-window .item-details {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 1px solid black;
  text-align: center;
}

.order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 480px;
}

.order-form {
  display: block;
  align-items: center;
}

.add-buttons {
  display: flex;
  flex-direction: column;
  /* Align buttons vertically */
  gap: 10px;
  /* Add spacing between buttons */
  margin-top: 10px;
  /* Optional: space above the button group */
  margin-left: 10px;
}

.modified-timestamp {
  font-size: 11px;
  margin: 0 auto;
  position: absolute;
}

.form-columns {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  flex-wrap: wrap;
}

.form-column {
  margin-right: 1rem;
  margin-left: 1rem;
  top: 0px;
}

.form-column:last-child {
  margin-right: 0;
}

.form-group {
  text-align: center;
  bottom: 0px;
}

.form-group-container {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 20px;
}

.form-group-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-bottom: 15px;

}

.form-group-checkbox label {
  bottom: 0px;
}

.form-group-checkbox input {
  margin-top: 13px;
}

.form-group-checkbox:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
  color: #000000;
}

.form-input {
  padding: 0.3rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  /* Added to align input text to the left */
}

.notes-label {
  text-align: center;
  border-radius: 4px;

}

.form-input-field {
  padding: 0.3rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  /* Added to align input text to the left */
  max-width: 500px;
  justify-content: center;
}

.form-button {
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 20px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px auto;
}

.form-button-order {
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 5px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  opacity: 0.8;
}

.form-button-submit {
  background-color: var(--submit-color, #007bff);
}

.form-button-new {
  background-color: var(--submit-color, #2dbd45);
}

.form-button-cancel {
  background-color: var(--cancel-color, #9a9a9a);
}

.form-button-delete {
  background-color: var(--delete-color, #FF5733);
}

.form-button-submit:hover {
  background-color: var(--submit-hover-color, blue);
}

.form-button-new {
  background-color: var(--submit-color, #138526);
}

.form-button-cancel:hover {
  background-color: var(--cancel-hover-color, #737373);
}

.form-button-delete:hover {
  background-color: var(--delete-hover-color, #CC0000);
}

.form-button-delete:disabled {
  background-color: var(--delete-hover-color, #e79191);
}

.form-button-submit:disabled {
  background-color: var(--submit-color, #9cbcdf);
}

.form-buttons-container {
  display: flex;
  justify-content: center;
  font-size: normal;
  color: black;
  margin-bottom: 15px;
  gap: 50px;
}

.customer-info {
  display: flex;
  justify-content: center;
  font-size: large;
  color: black;
  font-weight: bold;
  margin-bottom: 5px;
  height: 30px;
}

@media (max-width: 1080px) {
  .customer-info {
    margin-bottom: 25px;
  }
}

.customer-info .form-group {
  margin-right: 1rem;
}

.customer-info .form-group:last-child {
  margin-right: 0;
}

.checkbox-field {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}



.modified {
  background-color: #D3F4FB;
}

.submitted {
  background-color: #f0fff0;
  /* Change background color to light green when submitted */
}

.delete-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.delete-confirmation-window {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.confirmation-message {
  font-size: 18px;
  margin-bottom: 20px;
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
}

.confirm {
  background-color: #4CBB17;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.cancel {
  background-color: gray;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.confirm:hover {
  background-color: green;
  border-radius: 5px;
}

.cancel:hover {
  background-color: rgb(90, 88, 88);
  border-radius: 5px;
}

.modify-resources {
  font-family: Arial, sans-serif;
  max-width: 300px;
  margin: 0 auto;
}

.modify-resources-minutes {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
}

.modify-resources__title {
  font-size: 22px;
  margin-bottom: 20px;
  color: #000000;
}

.modify-resources__select {
  margin-bottom: 20px;
}

.modify-resources__select-box {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.modify-resources__form {
  display: flex;
  flex-direction: column;
}

.modify-resources__form-item {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modify-resources__label {
  display: block;
  font-size: 16px;
  margin-right: 10px;
}

.modify-resources__input {
  width: 80px;
  /* Adjust width as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.modify-resources__unit {
  margin-left: 10px;
  color: #000000;
}

.modify-resources__button {
  width: 100%;
  /* Adjust width as needed */
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 50px;
}

.modify-resources__button:hover {
  background-color: blue;
}

.Transaction-Container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  max-width: 750px;
  height: 75vh;
  /* 75% of the viewport height */
  width: 90%;
}

.Transaction-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Transaction-Title {
  margin: 10px;
}

.Transaction-CloseButton {
  background-color: rgb(184, 181, 181);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.Transaction-CloseButton:hover {
  background-color: gray;
}

.Transaction-ListContainer {
  background-color: #fff;
  width: 100%;
  max-height: 90%;
  overflow-y: auto;
}

.Transaction-List {
  list-style: none;
  padding: 0;
}

.Transaction-Item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.Transaction-Info {
  display: flex;
  align-items: center;
}

.Transaction-Person {
  font-weight: bold;
  margin-right: 10px;
}

.Transaction-ItemDetails,
.Transaction-OldItemDetails {
  margin-right: 10px;
  color: #000000;
}

.Transaction-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Transaction-OldItemInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-left: 20px;
  font-style: italic;
  color: #000000;
}

.Transaction-Amount {
  margin-right: 10px;
}

.positive {
  color: green;
  font-weight: bold;
}

.negative {
  color: red;
  font-weight: bold;
}

.default {
  color: #000000;
}

.Transaction-Time {
  margin-right: 10px;
  font-size: 12px;
  color: #888;
}

.Transaction-Action {
  font-weight: bold;
  font-style: italic;
  color: #888;
  margin-right: 10px;
}

.Transaction-ActionRemove {
  color: red;
}

.Transaction-ActionAdd {
  color: green;
}

.Transaction-ActionModified {
  color: orange;
}

.person-input-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.person-input-window {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.person-input-window h2 {
  margin-bottom: 20px;
}

.person-input-window input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.person-input-window .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.person-input-window .buttons .previous-name-button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.person-input-window .actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.person-input-window .actions button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.theheader {
  margin: 0 0 0 0;
  color: #000000;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3rem;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../images/Portaat.jpg') no-repeat center center fixed;
  background-size: cover;
  font-family: 'Arial', sans-serif;
}

.login-form {
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;

  text-align: center;
}

.login-form h2 {
  margin-bottom: 1rem;
  color: #333;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-button,
.register-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background: #6a11cb;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:disabled,
.register-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.login-button:not(:disabled):hover,
.register-button:not(:disabled):hover {
  background: #2575fc;
}

.users-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.user-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.user-form input,
.user-form select {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.user-list {
  list-style-type: none;
  padding: 0;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.btn {
  cursor: pointer;
}

.btn-edit {
  background-color: #28a745;
}

.btn-delete {
  background-color: #dc3545;
  margin-left: 10px;
}

.btn:hover {
  opacity: 0.8;
}

.users-header-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.notification-message {
  margin-left: 10px;
  font-weight: bold;
}

.notifications-container {
  position: absolute;
}

.error {
  color: red;
}

.success {
  color: green;
}

.loading-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  /* Set the height to match the container */

}

.loading-icon {
  width: 35px;
  /* Adjust size as needed */
  height: 35px;
  /* Adjust size as needed */
  border: 5px solid #ccc;
  /* Border color */
  border-top: 5px solid #3498db;
  /* Top border color, change as needed */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  /* Animation properties */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  /* Starting position */
  100% {
    transform: rotate(360deg);
  }

  /* Ending position */
}

@keyframes grow-spin {
  0% {
    transform: scale(0.1) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.pulse-spin {
  animation: grow-spin 0.5s ease-out forwards, pulse 1s infinite 0.5s;
}

.notification {
  display: flex;
  align-items: center;
}

.notification-text {
  margin-left: 10px;
  font-size: 1.2em;
  opacity: 0;
  /* Start hidden */
  animation: fadeIn 0.5s forwards 0.5s;
  /* Fade in after grow-spin */
}

.notification-success {
  color: green;
}

.notification-error {
  color: red;
}

.quote {
  font-size: 16px;
  margin-bottom: 50px;
  text-align: center;
  font-style: italic;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 180px;
  /* Adjust the width as needed */
}

.custom-dropdown-header {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2.7px 8px;
  /* Adjust padding to fit the smaller size */
  cursor: pointer;
  color: #000;
  /* Black text */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* Needed for positioning the arrow */
  font-size: 14px;
  /* Adjust font size if needed */
}

.custom-dropdown-header::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  /* Adjust border size */
  border-right: 5px solid transparent;
  /* Adjust border size */
  border-top: 5px solid #000;
  /* Arrow color, same as text */
  position: absolute;
  right: 10px;
  /* Adjust to position arrow correctly */
  top: 50%;
  transform: translateY(-50%);
  transition: border-top-color 0.3s ease;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.custom-dropdown-item {
  padding: 6px 8px;
  /* Adjust padding to fit the smaller size */
  cursor: pointer;
  color: #000;
  /* Black text */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 14px;
  /* Adjust font size if needed */
}

.custom-dropdown-item.hovered {
  background-color: #f0f0f0;
}

.custom-dropdown-delete {
  font-size: 14px;
  /* Adjust font size of delete icon */
  color: #f00;
  /* Red color for delete icon */
  cursor: pointer;
  position: relative;
  /* Ensure positioning is relative for the pseudo-element */
  z-index: 1;
  /* Make sure the "X" is on top */
}

.custom-dropdown-delete::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  /* Center the circle behind the icon */
  width: 25px;
  /* Adjust size if needed */
  height: 25px;
  /* Adjust size if needed */
  background-color: #d0d0d0;
  /* Darker grey background */
  border-radius: 50%;
  /* Make it a circle */
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  /* Ensure the circle is behind the "X" */
}

.custom-dropdown-delete:hover::before {
  opacity: 1;
  /* Show the circle on hover */
}

.custom-dropdown-actions {
  display: flex;
  gap: 8px;
}

.custom-dropdown-edit {
  font-size: 14px;
  /* Adjust font size of edit (pencil) icon */
  cursor: pointer;
  color: #00f;
  /* Blue color for edit icon */
  position: relative;
  z-index: 1;
}

.custom-dropdown-edit::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 25px;
  height: 25px;
  background-color: #d0d0d0;
  /* Darker grey background */
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.custom-dropdown-edit:hover::before {
  opacity: 1;
  /* Show the circle on hover */
}

.status-container {
  font-size: 13px; /* Base font size */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  flex-basis: 0;
}

.status-item {
  font-size: calc(1.5vw); /* Responsive font size */
  color: black;
  display: flex;
  align-items: center;
  margin-left: 2vw;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.status-block {
  width: 1.2vw; /* Adjust as needed */
  height: 1.2vw; /* Adjust as needed */
  display: inline-block;
  margin-right: 0.5vw;
}

/* Max font size limit */
@media (min-width: 1300px) { /* Adjust based on your design */
  .status-item {
    font-size: 20px;
  }
}

.jokke-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1000; /* Ensure the overlay is on top of other elements */
}

.jokke-image-overlay img {
  animation: spinAndGrow 5s ease forwards; /* Animation to spin and grow */
  max-width: 0;
  max-height: 0;
  object-fit: contain;
  border-radius: 10px;
}

@keyframes spinAndGrow {
  0% {
      transform: scale(0) rotate(0deg); /* Start at 0 size and no rotation */
      max-width: 0%;
      max-height: 0%;
  }
  100% {
      transform: scale(1) rotate(1080deg); /* Full size and 1080-degree spin (3 full rotations) */
      max-width: 80%;
      max-height: 80%;
  }
}

.hyvää-työtä-text {
  position: absolute;
  bottom: 10%;
  font-size: 3rem; /* Increased font size */
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px black; /* Black outline/shadow around the text */
  opacity: 0;
  animation: fadeInText 3s ease forwards 3s; /* Appear after 3 seconds */
}

@keyframes fadeInText {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.manufacturer-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2rem;
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
  color: #000;
}

.btn-add {
  background-color: #4CAF50;
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.manufacturer-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
  display: flex;
  flex-direction: column;  /* Changed to column for manufacturer and floor to be stacked */
}

.manufacturer-header {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: black;
}



.icon-container {
  margin-left: 30px;
  display: none; /* Hide icons by default */
  gap: 10px;
}

.manufacturer-header:hover .icon-container,
.icon-container.visible {
  display: flex; /* Show icons on hover or when toggled */
}

.manufacturer-header h2 {
  font-size: 1.5rem;
  margin-left: 5px;  /* Reduced margin between star and name */
}

.favorite-btn {
  background: none;
  border: 1px;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(221, 216, 216);
}

.favorite-btn.active {
  color: gold;
}

.favorite-btn:hover {
  color: #ffcc00;
}

.manufacturer-actions {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Reduced left margin for buttons */
}

.icon-btn {
  background: none;
  border: none;
  font-size: 1rem; /* Smaller size for the pen and trash can icons */
  cursor: pointer;
}

.icon-btn:hover {
  color: #007BFF;
}

.btn-add-floor {
  margin-left: 10px;
  background-color: #007BFF;
  padding: 5px 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-add-floor:hover {
  opacity: 0.8;
}

.floor-item {
  flex-wrap: wrap; /* Allow wrapping to next line */
  display: flex;
  padding: 5px 0;
  cursor: pointer;
  color: #000;
  transition: all 0.3s ease;
}

.floor-item:hover, 
.floor-item.active {
  color: black; /* Change text color */
  margin-left: 5px; /* Increase font size by 2px */
}

.icon-container {
  display: none; /* Hide by default */
  gap: 10px;
}

.floor-item:hover .icon-container,
.icon-container.visible {
  display: flex; /* Show icons on hover or when toggled */
}

.floor-name {
  font-weight: bold;
  margin-right: 5px;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 2px;
  color: white; /* Ensure icon color matches white text on black background */
}


.floor-item button {
  margin-left: 10px;
}

.btn-add:hover, 
.icon-btn:hover{
  opacity: 0.9; /* Slightly less transparent for a darker effect */
  filter: brightness(0.6); /* Darkens the element slightly */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}